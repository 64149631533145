import Widmung from '@/models/widmung';
import Infrastruktur from '@/models/infrastruktur';
import PolitischeGemeinde from '@/models/politischegemeinde';
import Katastralgemeinde from '@/models/katastralgemeinde';
import Bezirk from '@/models/bezirk';

/**
 * get the formatted center to be used in routes
 * @param {Array<number>} center Array of coordinates
 * @returns {string}
 */
export function getFormattedCenter(center) {
  return center.map(n => n.toFixed(4).replace(/\.?0+$/, '')).join(',');
}

/**
 * get the formatted area
 * @param {number} value area in square meters
 * @returns {string}
 */
export function getFormattedAreaString(value) {
  return value.toLocaleString('de', {maximumFractionDigits: 2}) + ' m²';
}

export function getFormattedPrice(value) {
  return value.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});
}

const normalObjects = ['Grundstück', 'Betriebsobjekt', 'Bürofläche'];
export function isBpOrTz(typ) {
  return !normalObjects.includes(typ);
}

export const objektTypes = {
  'Grundstück': 'Grundstück',
  'Bürofläche': 'Büro',
  'Betriebsobjekt': 'Gebäude',
  'WPParndorf': 'Businesspark Parndorf / Neusiedl am See',
  'WPMüllendorf': 'Businesspark Müllendorf',
  'WPKittsee': 'Businesspark Kittsee',
  'WPHeiligenkreuz': 'Businesspark Heiligenkreuz',
  'WPOberpullendorf': 'Businesspark Oberpullendorf',
  'WPRudersdorf': 'Businesspark Rudersdorf',
  'TZEisenstadt': 'Technologiezentrum Eisenstadt',
  'TZNeusiedl': 'Technologiezentrum Neusiedl',
  'TZMittelburgenland': 'Technologiezentrum Mittelburgenland',
  'TZPinkafeld': 'Technologiezentrum Pinkafeld',
  'TZGuessing': 'Technologiezentrum Güssing',
  'TZJennersdorf': 'Technologiezentrum Jennersdorf'
};

export function getFormattedTyp(value) {
  return objektTypes[value];
}


const statusDictionary = {
  0: 'Entwurf',
  1: 'Offline',
  10: 'Online',
  20: 'Vermittelt'
};

/**
 * @param {number} status
 * @returns {string}
 */
export function getTextForStatus(status) {
  return statusDictionary[status];
}


/**
 * get the formatted widmung list for an array of widmung-codes
 * @param {Array<number>} widmungList
 */
export function getFormattedWidmung(widmungList) {
  const wmList = [];
  const allWidmung = Widmung.all();
  // @ts-ignore
  allWidmung.sort((a, b) => a.kuerzel.toUpperCase() > b.kuerzel.toUpperCase() ? 1 : -1);
  for (let i = 0; i < widmungList.length; i++) {
    if (widmungList[i] === 0) {
      continue;
    }
    const widmung = allWidmung.find(widmung => {
      // @ts-ignore
      return widmung.id === widmungList[i];
    });
    // @ts-ignore
    wmList.push(widmung.bezeichnung);
  }
  return wmList.length ? wmList : ['Ohne'];
}


export function getInfraList(infrastruktur) {
  if (!infrastruktur) {
    return [];
  }
  const infraListAll = [];
  const allInfra = Infrastruktur.all();
  const infraAssignedList = JSON.parse(infrastruktur);

  for (let k = 0; k < allInfra.length; k++) {
    if (infraAssignedList.indexOf(allInfra[k]['id']) > -1) {
      infraListAll.push({id: allInfra[k]['id'], bezeichnung: allInfra[k]['bezeichnung']});
    }
  }
  infraListAll.sort((a, b) => a.bezeichnung > b.bezeichnung ? 1 : -1);
  return infraListAll;
}

/**
 * returns true if given katastralgemeinde-ID is a Sued-Bezirk
 * @param {number} katastralgemeinde
 * @returns {boolean}
 */
export function isSuedGemeinde(katastralgemeinde) {
  return false;
  //@ts-ignore
  //const bezirkId = (katastralgemeinde === 0) ? 0 : Katastralgemeinde.find(katastralgemeinde).bezirk;
  //return [105, 104, 109].includes(bezirkId);
}


export function getPolGemNameForKatGem(katGem) {
  //@ts-ignore
  const politischegemeinde = (katGem == 0) ? 0 : Katastralgemeinde.find(katGem).politischegemeinde;
  //@ts-ignore
  return (politischegemeinde === 0) ? '' : PolitischeGemeinde.find(politischegemeinde).name;
}

export function getBezNameForKatGem(katGem) {
  //@ts-ignore
  const bezirk = (katGem === 0) ? 0 : Katastralgemeinde.find(katGem).bezirk;
  //@ts-ignore
  return bezirk === 0 ? '' : Bezirk.find(bezirk).name;
}

export function getBezIdForKatGem(katGem) {
  //@ts-ignore
  const bezirk = (katGem === 0) ? 0 : Katastralgemeinde.find(katGem).bezirk;
  return bezirk;
}
